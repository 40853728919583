import styled from "styled-components";

export const MobileContainer = styled.div`
  background-image: url("/images/app-mobile-bg.svg");
  background-size: cover;
`;

export const ContentHomePage = styled.div`
  margin-bottom: 35px;
  display: flex;
  margin: 0 0 35px;
`;

export const ContentBlock = styled.div`
  width: 100%;
`;

export const Hello = styled.div`
  display: flex;
  font-size: 18px;
  font-weight: 600;
  line-height: 24.38px;
  text-align: left;

  h2 {
    font-weight: 600;
    font-size: 16px;
  }

  p {
    margin-left: 5px;
    color: #413fcf;
  }
`;

export const BalanceContainer = styled.div`
  display: flex;
  margin: 16px 0px 32px;
  gap: 16px;
`;

export const OperationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: calc(55% - 30px);
`;

export const SwapContainer = styled.div`
  h2 {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    p {
      font-size: 16px;
      font-weight: 600;
      line-height: 19.5px;
      text-align: left;
      margin-left: 10px;
    }
  }
`;
