/* eslint-disable */
import { api } from "@/apis/axios";
import { ICustomer, IRequiredField } from "@/models";
import moment from "moment";
import "moment/locale/pt-br";
import i18n from "../../i18n";
import { isValid } from "iban-ts";
import { AxiosResponseHeaders, RawAxiosResponseHeaders } from "axios";

export function isDev() {
  return process.env.NODE_ENV === "development";
}

export function isProd() {
  return process.env.NODE_ENV === "production";
}

export function isClient() {
  return global.window !== undefined;
}

export function isServer() {
  return global.window === undefined;
}

export function isObjEmpty(obj: any) {
  return JSON.stringify(obj) === "{}";
}

export function isAnInstance(obj: any) {
  return typeof obj.prototype === "undefined";
}

export function isAnArray(obj: any) {
  return obj?.constructor.name === "Array";
}

export function isAllTrue(array: any[]) {
  return !array.includes(false);
}

export function isObjParseable(source: any, target: any) {
  const isParseableCollection: boolean[] = [];
  const instances: any[] = [];
  const sources: any[] = [];

  if (isAnArray(target)) {
    target.map((element: any) => {
      const instance = isAnInstance(element) ? element : new element();
      instances.push(instance);
    });
  } else {
    const instance = isAnInstance(target) ? target : new target();
    instances.push(instance);
  }

  if (isAnArray(source)) {
    source.map((element: any) => {
      sources.push(element);
    });
  } else {
    sources.push(source);
  }

  if (sources.length === 0) {
    return true;
  }

  sources.map((theSource: any) => {
    let tempIsParseable = true;
    const tempIsParseableCollection: boolean[] = [];
    instances.map((theInstance: any) => {
      tempIsParseable = true;
      Object.keys(theSource).map((key) => {
        if (typeof key === "string") {
          if (typeof theInstance[key] !== typeof theSource[key]) {
            tempIsParseable = false;
          }

          if (typeof theInstance[key] === "object") {
            if (!isObjParseable(theSource[key], theInstance[key])) {
              tempIsParseable = false;
            }
          }
        }
      });
      tempIsParseableCollection.push(tempIsParseable);
    });
    isParseableCollection.push(tempIsParseableCollection.includes(true));
  });

  return isAllTrue(isParseableCollection);
}

export function compareStrings(key: string) {
  return (a: any, b: any) => {
    if (a[key] < b[key]) {
      return -1;
    }
    if (a[key] > b[key]) {
      return 1;
    }
    return 0;
  };
}

export function isDate(date: string) {
  return !isNaN(new Date(date).getDate());
}

export function getOnlyDate(fullDate: string) {
  if (fullDate) {
    const arrDate = fullDate.split("T");
    if (arrDate[0] && isDate(arrDate[0])) {
      return arrDate[0];
    }
  }
  return null;
}

export function strReplacer(
  str: string,
  values: string[],
  replaceable = "0x0x0x"
) {
  if (!values || !values.length || !str.includes(replaceable)) {
    return str;
  }

  const regex = new RegExp(replaceable, "g");
  const amount: number = (str.match(regex) || []).length;
  const length = values.length;

  if (length !== amount) {
    return str;
  }

  let newStr = "";

  str.split(replaceable).map((item: string, index: number) => {
    if (index > length) {
      return;
    }
    const concatIt = values[index] ? values[index] : "";
    newStr = newStr + item + concatIt;
  });

  return newStr.replace(/\s+/g, " ").trim();
}

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function capitalizeWords(str: string) {
  return str
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function convertStrPixelToInt(strPixel: string) {
  return parseInt(strPixel.replace("px", ""));
}

export function getError(errors: any, key: string) {
  return errors?.[key]?.message ? errors?.[key]?.message : null;
}

export function getErrorFromApi(error: any, defaultErrorMessage: string) {
  if (error?.errors?.[0]?.reason) {
    const errorCode = error.errors[0].code;
    if (
      errorCode === "MinimumLengthValidator" ||
      errorCode === "PredicateValidator"
    ) {
      return i18n.t("changePasswordForm.newPasswordText");
    } else if (errorCode === "InvalidSecurityCode") {
      return i18n.t("verifyCodeForm.verificationInvalidCode");
    }

    switch (errorCode) {
      case "DEPOSIT_INFO_NOT_FOUND":
        return i18n.t("cryptoDeposit.deposit_info_not_found");
      default:
        return error?.errors?.[0]?.reason;
    }
  } else if (error?.title) {
    return error?.title;
  } else {
    return defaultErrorMessage;
  }
}

export function isInRequiredField(requiredFields: IRequiredField[] | null) {
  return (key: string) => {
    if (!requiredFields) return false;

    const item = requiredFields.filter(
      (item: IRequiredField) => item.name === key
    );
    return item.length > 0;
  };
}

export function numberWithSeparator(value: number, separator = ",") {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);
}

export function getFormattedZero(digits: number, decimalSymbol: string) {
  return parseFloat("0").toFixed(digits).replace(".", decimalSymbol);
}

interface IReturnLogo {
  [key: string]: any;
}

type IParentType = string;
type IChildren = string;

export const returnTypeIconHistory: IReturnLogo = {
  card: "/images/operations/card.svg",
  deposit: "/images/operations/deposit.svg",
  withdrawal: "/images/operations/withdraw.svg",
  trade: "/images/operations/conversion.svg",
};

export const returnTypeIconDetailsHistory = (
  parentType: IParentType,
  childrenType: IChildren
) => {
  const icons: IReturnLogo = {
    card: {
      label: i18n.t("returnTypeIconDetailsHistory.card"),
      icon: "/images/operations/card.svg",
    },
    deposit: {
      label: i18n.t("returnTypeIconDetailsHistory.deposit"),
      account: {
        bonification: "/images/icons/gift.svg",
        brazillianpix: "/images/operations/deposit.svg",
        offchain: "/images/icons/crypto.svg",
        crypto: "/images/icons/crypto.svg",
        bank: "/images/operations/deposit.svg",
      },
    },
    withdrawal: {
      label: i18n.t("returnTypeIconDetailsHistory.deposit"),
      account: {
        bonification: "/images/icons/gift.svg",
        brazillianpix: "/images/operations/withdraw.svg",
        offchain: "/images/icons/crypto.svg",
        crypto: "/images/icons/crypto.svg",
        bank: "/images/operations/withdraw.svg",
        iban: "/images/icons/crypto.svg",
      },
    },
    trade: {
      label: i18n.t("returnTypeIconDetailsHistory.card"),
      icon: "/images/operations/conversion.svg",
    },
  };

  return childrenType
    ? icons[parentType as keyof typeof icons]?.account[childrenType]
    : icons[parentType as keyof typeof icons]?.icon;
};

export const formatDate = (date: string, pattern: string, locale?: string) => {
  const _date = moment(date)
    .locale(locale || "pt-BR")
    .format(pattern)
    .split("às");

  return _date;
};

function validateCPF(cpf: string) {
  const cleanedCPF = cpf.replace(/\D/g, ""); // Remove non-digit characters

  if (!/^\d{11}$/.test(cleanedCPF)) {
    return false;
  }

  if (/^(\d)\1{10}$/.test(cleanedCPF)) {
    return false;
  }

  const digits = cleanedCPF.split("").map(Number);
  const [a, b, c, d, e, f, g, h, i] = digits;

  const firstDigit =
    (a * 10 + b * 9 + c * 8 + d * 7 + e * 6 + f * 5 + g * 4 + h * 3 + i * 2) %
    11;
  const firstCheckDigit = firstDigit < 2 ? 0 : 11 - firstDigit;

  if (digits[9] !== firstCheckDigit) {
    return false;
  }

  const secondDigit =
    (a * 11 +
      b * 10 +
      c * 9 +
      d * 8 +
      e * 7 +
      f * 6 +
      g * 5 +
      h * 4 +
      i * 3 +
      firstCheckDigit * 2) %
    11;
  const secondCheckDigit = secondDigit < 2 ? 0 : 11 - secondDigit;

  return digits[10] === secondCheckDigit;
}

export const isValidEmail = (email: string) =>
  /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

export const isValidPhone = (phone: string) =>
  /^(\+55)?\s?\(?[1-9]{2}\)?\s?[9]?[6789]\d{3}-?\d{4}$/.test(phone);

export const iSvalidCPF = (cpf: string) =>
  /^([0-9]{3}\.?[0-9]{3}\.?[0-9]{3}-?[0-9]{2})$/.test(cpf);

export const isValidCnpj = (cnpj: string) =>
  /^(\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2})|(\d{14})$/.test(cnpj);

export const isRandomKey = (key: string) =>
  /^[0-9A-Za-z@!#$&*+=?\^_~.-]+$/.test(key) &&
  key.length >= 15 &&
  key.length <= 36;

export const verifyType = (key: string) => {
  if (validateCPF(key)) {
    return "CPF";
  } else if (isValidCnpj(key)) {
    return "CNPJ";
  } else if (isValidEmail(key)) {
    return "Email";
  } else if (isValidPhone(key)) {
    return "MobilePhone";
  } else if (isRandomKey(key)) {
    return "Random";
  } else {
    return "";
  }
};

export const typeErrorPixKeys: any = {
  LIMIT_AMOUNT: "typeErrorPix.limitAmount",
  EXPIRED_TRANSACTION: "typeErrorPix.expiredTransaction",
  INSUFFICIENT_BALANCE: "typeErrorPix.insufficientBalance",
  INVALID_TRANSACTIONAL_PASSWORD: "typeErrorPix.invalidTransactionalPassword",
  TRANSACTION_FREQUENCY_EXCEEDED: "typeErrorPix.transactionFrequencyExceed",
  IDENTICAL_TRANSACTION_24HOURS: "typeErrorPix.identicalTransaction24H",
};

export const typeErrorCustomerKeys = {
  CUSTOMER_NOT_FOUND: "stepThreeForm.userAlreadyExists",
};

export const typeErrorOnboardingKeys = {
  DUPLICATED_PHONE_NUMBER: "individualOnboardingForm.duplicatedPhoneNumber",
  DUPLICATED_TAXID_TAX_COUNTRY_CODE:
    "individualOnboardingForm.duplicatedTaxIdCPF",
};

export const typeErrorFavoriteKeys = {
  DUPLICATED_FAVORITE_ADDRESS: "addFavorite.favError.duplicated",
};

const cepRegex = /^\d{5}-?\d{3}$/;

function validateCEP(cep: string): boolean {
  return cepRegex.test(cep);
}

export const searchCep = async (cep: string) => {
  if (validateCEP(cep)) {
    try {
      const response = await api.get(`https://viacep.com.br/ws/${cep}/json/`);
      return response;
    } catch (error: any) {
      return error.message;
    }
  }
};

type PasswordRules = {
  length: boolean;
  uppercase: boolean;
  lowercase: boolean;
  number: boolean;
  symbol: boolean;
  match: boolean;
};

export const getPasswordRules = (
  newPassword: string,
  newPasswordConfirmation: string
): PasswordRules | undefined => {
  if (!newPassword) {
    return undefined;
  }
  const passwordRules: PasswordRules = {
    length: newPassword.length >= 8,
    uppercase: /[A-Z]/.test(newPassword),
    lowercase: /[a-z]/.test(newPassword),
    number: /\d/.test(newPassword),
    symbol: /[^A-Za-z0-9]/.test(newPassword),
    match: newPassword === newPasswordConfirmation,
  };

  return passwordRules;
};

export const formatTaxId = (data: ICustomer | null) => {
  const taxId = data?.taxId;

  if (data?.taxIdCountry !== "BRA" || !taxId) {
    return taxId;
  }

  const pattern =
    data?.type === "Individual" ? "$1.$2.$3-$4" : "$1.$2.$3/$4-$5";

  return taxId.replace(
    data?.type === "Individual"
      ? /(\d{3})(\d{3})(\d{3})(\d{2})/
      : /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
    pattern
  );
};

export const individualLevels = ["Basic", "Premium"];
export const PJLevels = ["Basic", "StandardCompany"];

export const formatTargetTaxId = (taxId: string, type: string) => {
  const clearTaxId = taxId.replace(/\D/g, "");

  const formattedTaxId =
    type === "CPF"
      ? clearTaxId.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, "$1.$2.$3-$4")
      : clearTaxId.replace(
          /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
          "$1.$2.$3/$4-$5"
        );

  return formattedTaxId;
};

export const statusInfoMap = {
  Allow: {
    icon: "approvedIcon",
    title: "cardsTransfero.modalStatus.Allow.title",
    description: "cardsTransfero.modalStatus.Allow.description",
    buttonTitle: "cardsTransfero.modalStatus.Allow.buttonTitle",
    route: "/card",
    status: "Allow",
  },
  NotAllow: {
    icon: "analysisIcon",
    title: "cardsTransfero.modalStatus.NotAllow.title",
    description: "cardsTransfero.modalStatus.NotAllow.description",
    buttonTitle: "cardsTransfero.modalStatus.NotAllow.buttonTitle",
    route: "",
    status: "NotAllow",
  },
  Forbidden: {
    icon: "reprovedIcon",
    title: "cardsTransfero.modalStatus.Forbidden.title",
    description: "cardsTransfero.modalStatus.Forbidden.description",
    buttonTitle: "cardsTransfero.modalStatus.Forbidden.buttonTitle",
    route: "/help-center/contact",
    status: "Forbidden",
  },
  Pending: {
    icon: "reprovedIcon",
    title: "cardsTransfero.modalStatus.Pending.title",
    description: "cardsTransfero.modalStatus.Pending.description",
    buttonTitle: "cardsTransfero.modalStatus.Pending.buttonTitle",
    route: "",
    status: "Pending",
  },
};

export function hideSensitiveData(obj: any) {
  function checkObj(_obj: any): any {
    for (const key in _obj) {
      if (typeof _obj[key] === "object") {
        checkObj(_obj[key]);
      } else {
        try {
          let rep = JSON.parse(_obj[key]);
          [
            "password",
            "transactionalPassword",
            "securityCode",
            "confirmationCode",
            "confirmationPassword",
            "newPassword",
            "newPasswordConfirmation",
            "resetPasswordCode",
          ].forEach((_key) => {
            if (_key in rep) {
              rep[_key] = "******";
              _obj[key] = JSON.stringify(rep);
            }
          });
        } catch (err) {}
      }
    }
  }
  function checkToken(_obj: any): any {
    for (const key in _obj) {
      if (typeof _obj[key] === "object") {
        checkToken(_obj[key]);
      } else {
        try {
          if (_obj[key].includes("Bearer ")) {
            _obj[key] = _obj[key].replace(
              /Bearer\s+([^\s,]*)/,
              "Bearer ***********************************************************"
            );
          }
        } catch (err) {}
      }
    }
  }
  checkObj(obj);
  checkToken(obj);
  return obj;
}

export const formatDateBrazil = (value: string) => {
  const dateString = value;
  const date = new Date(dateString);

  const options: any = { day: "2-digit", month: "2-digit", year: "numeric" };
  const formattedDate = date.toLocaleDateString("pt-BR", options);

  return formattedDate;
};

export function checkDate(dateString: string) {
  const date = new Date(dateString);
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  if (date.toDateString() === today.toDateString()) {
    return "today";
  }

  if (date.toDateString() === yesterday.toDateString()) {
    return "yesterday";
  }

  return dateString;
}

export const formatSubmitDate = (inputDate: string) => {
  let isoDateString;
  if (inputDate.length >= 10) {
    let parts = inputDate.split("/");
    let day = parseInt(parts[0]);
    let month = parseInt(parts[1]);
    let year = parseInt(parts[2]);

    let dateObj = new Date(year, month - 1, day);

    isoDateString = dateObj.toISOString();
  }

  return isoDateString;
};

export const formatDateHour = (dateString: string) => {
  const formattedDate = moment
    .utc(dateString)
    .local()
    .format("DD/MM/YYYY - HH:mm");
  return formattedDate;
};

export const customerInitials = (fullName: string) => {
  if (fullName) {
    const [firstName, ...midName] = fullName.split(" ");
    const lastName = midName.pop();
    return firstName?.charAt(0) + (lastName ? lastName.charAt(0) : "") || "";
  }
  return "";
};

export function formatarData(data: Date): string {
  const dia = ("0" + data.getDate()).slice(-2);
  const mes = ("0" + (data.getMonth() + 1)).slice(-2);
  const ano = data.getFullYear();

  return `${dia}/${mes}/${ano}`;
}

type LinkConfig = {
  [key: string]: { targetText: string; link: string };
};
export function createLinkInText(
  text: string,
  linkConfig: LinkConfig,
  language: string
): string {
  const { targetText, link } = linkConfig[language];
  const replacement = `<a target="_blank" href="${link}">${targetText}</a>`;
  return text.replace(targetText, replacement);
}

export function formatDateMonth(input: string): string {
  if (typeof input !== "string") return input;
  const [day, month] = input.split("/");
  return `${day}/${month}`;
}

export function formatValue(value: number, decimalPlaces: number): string {
  if (decimalPlaces < 0) {
    throw new Error("The number of decimal places cannot be negative.");
  }

  const divisor = Math.pow(10, decimalPlaces);
  const roundedValue = Math.round(value * divisor) / divisor;
  const formattedValue = roundedValue.toFixed(decimalPlaces);

  return formattedValue;
}

export function validateIBAN(iban: string): boolean {
  return isValid(iban);
}

export function removeEmptyParams<T>(params: T): T {
  const cleanedParams: T = {} as T;
  for (const key in params) {
    if (
      params[key] !== undefined &&
      params[key] !== null &&
      params[key] !== ""
    ) {
      cleanedParams[key] = params[key];
    }
  }
  return cleanedParams;
}

export function getPaginationFilters(
  headers: RawAxiosResponseHeaders | AxiosResponseHeaders
) {
  const pageSize = headers["x-page-size"];
  const totalCount = headers["x-total-count"];
  const totalPages = headers["x-total-pages"];
  const currentPage = headers["x-current-page"];

  return {
    pageSize,
    totalCount,
    totalPages,
    currentPage,
  };
}
