import styled, { keyframes } from "styled-components";

export const ContentLogoAndLanguage = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ContentLanguage = styled.div`
  font-size: 14px;
  color: #413fcf;
  font-weight: 500;
  position: relative;

  & > div {
    cursor: pointer;
  }

  img {
    margin-right: 10px;
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const DropdownLanguage = styled.div<{ open: string }>`
  display: ${({ open }) => open};
  width: 190px;
  position: absolute;
  top: 60px;
  right: -20px;
  box-shadow: 0px 4px 6px -2px #10182808;
  box-shadow: 0px 12px 16px -4px #10182814;
  border-radius: 12px;
  border: 1px solid #eaecf0;
  background-color: #fff;
  animation: ${fadeIn} 0.3s ease-in-out;
  z-index: 10;
`;

export const Item = styled.div`
  width: 100%;
  height: 40px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  color: #30374f;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 6px;

  &:hover {
    background-color: #ebecef;
    border-radius: 6px;
  }
`;
